import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import styled from "styled-components";
import "react-tabs/style/react-tabs.css";

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`;

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`;
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`;

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`;
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`;
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`;

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const IndexPage = () => (
  <Layout>
    <hr />

    <div className="bg-gray-100 min-h-screen flex items-center justify-center py-10">
      <div className="max-w-3xl mx-auto p-8 bg-white rounded-lg shadow">
        <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>

        {/* <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Terms</h2>

          <p className="text-gray-700 mb-4">
            <strong>Merchants:</strong> Individuals or businesses who operate an ecommerce shop on the Shopify platform and use our app to enhance their store's functionality.
          </p>

          <p className="text-gray-700 mb-4">
            <strong>Visitors:</strong> Individuals who access the online stores created by the Shopify merchants and interact with our app's features or functionalities.
          </p>

          <p className="text-gray-700 mb-8">
            <strong>Non-Shopify Merchant Users:</strong> Individuals or businesses using our app on custom-built websites or other ecommerce platforms to access its features and functionalities.
          </p>
        </div> */}

        <div>
          <h2 className="text-2xl font-bold mb-4">1 Data We Collect</h2>

          <p className="text-gray-700 mb-4">
            We are committed to transparency. We disclose the following personal data to our users:
          </p>

          {/* 1.1 For Non-Shopify Apps: */}

          <p className="text-gray-700 mb-3">
            <strong>1.1 For Shopify Apps:</strong>
          </p>

          <ul className="list-disc ml-6 text-gray-700">
            <li className="mb-2">Merchant's store name</li>
            <li className="mb-2">Merchant's email address</li>
            <li className="mb-2">Merchant's customer data:
              <ul className="list-disc ml-8 text-gray-700 my-5">
                <li className="mb-2">First name</li>
                <li className="mb-2">Last name</li>
                <li className="mb-2">Email address</li>
                <li className="mb-2">Billing address</li>
                <li className="mb-2">Shipping address</li>
              </ul>
            </li>
            <li className="mb-2">We refer to this information as "Shopify Information"</li>
          </ul>


          <p className="text-gray-700">
            By disclosing this personal data to  our users, we aim to ensure transparency and provide users with a clear understanding of the data that is processed and the purposes for processing it. We are committed to maintaining the confidentiality and security of this personal data in accordance with this privacy policy.
          </p>
          {/* 1.2 For Non-Shopify Apps: */}
          <p className="text-gray-700 mb-2 py-5">
            <strong>1.2 For Non-Shopify Apps:</strong>
          </p>
          <p className="text-gray-700">
            For Non-Shopify Apps: For our non-Shopify apps, we disclose the personal data provided by the merchant directly or generated through their interactions with the app, including but not limited to the following information:
          </p>
          <ul className="list-disc ml-6 text-gray-700 my-2">
            <li className="mb-2">
              <b>User Account Information:</b> When you create an account for our non-Shopify app, we may collect personal information such as your name, email address, and password.
            </li>
            <li className="mb-2">
              <b>App Usage Information:</b> When you use our non-Shopify app, we may collect information about your interactions, preferences, and activities within the app. This may include data such as app feature usage, settings, and user-generated content.
            </li>
            <li className="mb-2">
              <b>Device Information:</b> We may collect certain information about the device you use to access our non-Shopify app, such as device type, operating system, IP address, and browser type.
            </li>
          </ul>

          {/* 1.3 For Non-Shopify Apps: */}
          <p className="text-gray-700 mb-2 py-5">
            <strong>1.3 Additional data Provided by Merchants:</strong>
          </p>
          <p className="text-gray-700">
            When merchants install our app, they may have the option to upload videos, conduct Zoom meetings, and upload files through our app. In such cases, we may collect and store this additional data to enable the functionality of these features. The types of data we may collect include:
          </p>
          <ul className="list-disc ml-6 text-gray-700 my-2">
            <li className="mb-2">
              <b>Videos:</b> Merchants may upload videos through our app for various purposes, such as course content. These videos are securely stored and associated with the merchant's account.
            </li>
            <li className="mb-2">
              <b>Zoom Meetings:</b>Merchants may use our app to schedule and conduct Zoom meetings with their customers. While we do not directly collect the content of these meetings, we may collect metadata related to the meetings, such as meeting times, participants, and meeting durations.
            </li>
            <li className="mb-2">
              <b>Files:</b> Merchants may upload media files, such as MP3, MP4, AVI, PDFs, ePUBs, DOCX, JPEG, GIF, JPG, PNG and such as may constitute course materials, other types of documents, or presentations, while using the app. These files are securely stored and associated with the merchant's account.
            </li>
          </ul>

          <p className="text-gray-700">
            All collected personal information, including videos, Zoom meeting metadata, and uploaded files, is treated with utmost confidentiality and is used solely for the purposes outlined in this Privacy Policy.
          </p>

          {/* 1.4 For Non-Shopify Apps: */}
          <p className="text-gray-700 mb-2 py-5">
            <strong>1.4 Usage Data:</strong>
          </p>
          <p className="text-gray-700">
            We may collect information about how you interact with the App, including your access times, pages viewed, features used, and other analytical data.
          </p>

          {/* 1.5 For Non-Shopify Apps: */}
          <p className="text-gray-700 mb-2 py-5">
            <strong>1.5 Cookies and Tracking Technologies</strong>
          </p>
          <p className="text-gray-700">
            We may use cookies and other tracking technologies to collect information about your usage of the App and improve your experience. This may include your IP address, browser type, device information, and other browsing data.
          </p>

          <h2 className="text-2xl font-bold mb-4 my-5">2. Uses of collected data</h2>
          <p className="text-gray-700">
            We only collect personal information that is necessary to provide and improve the functionality of our app. Some of the uses of the collected personal data included but not limited to:
          </p>

          <ul className="list-disc ml-8 text-gray-800 mb-2">
            <li className="mb-2">Improve our app's performance</li>
            <li className="mb-2">Communicate with the merchant regarding app-related updates or support.</li>
            <li className="mb-2">Managing billing and shipping details of purchases</li>
            <li className="mb-2">Personalizing user experience and app related communication</li>
            <li className="mb-2">Ensuring accurate delivery of purchased content</li>
            <li className="mb-2">Facilitating customer support and communication</li>
            <li className="mb-2">Provide customer usage statistics to merchants</li>
            <li className="mb-2">We may use anonymized and aggregated data for statistical purposes, market research, and to improve the App's performance and features</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 my-5">3. Minimum Personal Data Processing</h2>
          <p className="text-gray-700 py-5">
            We are committed to processing the minimum personal data required to provide value to merchants. We understand the importance of data minimization and strive to collect and process only the necessary personal data to fulfill the purposes outlined in this privacy policy.
          </p>
          <p className="text-gray-700 py-5">
            Our data processing practices are designed to respect privacy and ensure the security of personal information. We aim to avoid unnecessary collection and processing of personal data that is not essential for the provision of our services.
          </p>
          <p className="text-gray-700">
            We regularly review our data processing practices to ensure compliance with applicable data protection laws and regulations and to minimize the personal data we collect and process.
          </p>
        </div>
        <h2 className="text-2xl font-bold mb-4 my-5">4. Data Security</h2>
        <p className="text-gray-700 py-5">
          We take appropriate measures to protect the security and integrity of the data provided by merchants. All sensitive personal data collected is encrypted both during transmission and storage. We implement industry-standard security measures to protect collected data against unauthorized access, disclosure, or alteration. Additionally, we regularly conduct security audits and employ strict access controls to safeguard collected data
        </p>
        <p className="text-gray-700 py-5">
          <b>Data Backups: </b>We take the security of your data seriously, including data backups. We employ industry-standard encryption measures to ensure that data backups are encrypted and protected from unauthorized access or disclosure. This encryption helps safeguard your personal information even in the event of a backup restoration process.
        </p>

        <p className="text-gray-700 py-5">
          It is important to remember that while we implement appropriate measures to protect personal information, no method of transmission or storage is completely secure. Therefore, we cannot guarantee absolute security of personal information. We encourage merchants and their customers to take precautions and use strong passwords, maintain the confidentiality of their account information, and promptly inform us of any unauthorized access or use of their accounts.
        </p>

        <h2 className="text-2xl font-bold mb-4">5. Data Retention</h2>
        <p className="text-gray-700 py-5">
          We retain all collected data only for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law. Once the data is no longer needed, we take steps to securely delete or anonymize it to prevent unauthorized access or use.
        </p>

        <p className="text-gray-700 py-5">
          If a merchant chooses to delete or uninstall our app, we will promptly delete all personal information associated with their account, including any data collected from their customers. This ensures that their personal information is no longer retained within our systems.
          Note that even though we take measures to securely delete or anonymize personal information, residual copies may remain in our backup systems for a limited period of time. However, these backups are securely stored and access to them is restricted to prevent unauthorized use.
        </p>
        <h2 className="text-2xl font-bold mb-4">6. Data Sharing</h2>
        <p className="text-gray-700 py-5">
          We do not share any collected  personal data with any third party unless required by law or governmental request. We do not sell or share this information with third parties, except as necessary to provide our services or if required to do so by law, regulation, or legal process.
        </p>

        <h2 className="text-2xl font-bold mb-4">7. Data Separation</h2>
        <p className="text-gray-700 py-5">
          Separating test and production data helps us maintain the confidentiality and reliability of your personal information. We employ secure infrastructure and access controls to maintain the separation and prevent unauthorized access to production data during testing or development activities.
        </p>

        <p className="text-gray-700 py-5">
          While we take precautions to keep the test and production data separate, it is important to note that in certain circumstances, limited data may be used for testing purposes. However, any data used for testing is handled with the same level of security and confidentiality as production data, and appropriate measures are taken to protect its integrity.
        </p>

        <p className="text-gray-700 py-5">
          By implementing data separation practices, we aim to uphold the highest standards of data privacy and security to ensure the protection of your personal information.
        </p>

        <h2 className="text-2xl font-bold mb-4">8. Data Loss Prevention</h2>

        <p className="text-gray-700 py-5">
          We are committed to safeguarding your personal information and have implemented a comprehensive data loss prevention strategy to minimize the risk of data loss, unauthorized access, or unauthorized disclosure. Our data loss prevention measures include:
        </p>
        <ul className="list-disc ml-8 text-gray-800 mb-2">
          <li className="mb-2"><b>Regular data backups: </b>We regularly back up our systems to ensure the availability and recoverability of data in the event of any unforeseen incidents.</li>
          <li className="mb-2"><b>Redundancy and fault-tolerant architecture:</b> Our infrastructure is designed with redundancy and fault-tolerant mechanisms to mitigate the impact of hardware or software failures and ensure the continuous availability of your data.</li>
          <li className="mb-2"><b>Firewalls and security measures: </b>We maintain robust firewalls and employ industry-standard security measures to protect against unauthorized access, data breaches, and other malicious activities.</li>
          <li className="mb-2"><b>Access controls:</b> We have strict access controls in place to limit access to personal information to authorized personnel only. These controls are regularly reviewed and updated to ensure the principle of least privilege is maintained.</li>
          <li className="mb-2"><b>Employee training and awareness:</b> We provide regular training to our employees to raise awareness about data protection best practices, including data loss prevention.</li>
        </ul>
        <p className="text-gray-700 py-5">
          While we have taken these measures to prevent data loss, it is important to acknowledge that no system is completely immune to risks. In the event of any data loss or breach, we have incident response protocols in place to promptly identify and mitigate the impact of such incidents.
        </p>

        <h2 className="text-2xl font-bold mb-4">9. Access to Personal Data</h2>
        <p className="text-gray-700 py-5">
          We understand the importance of maintaining the confidentiality and security of your personal data. To ensure the protection of your information, we have implemented strict access controls and procedures to limit staff access to customers' personal data. Our access control measures include:
        </p>

        <ul className="list-disc ml-8 text-gray-800 mb-2">
          <li className="mb-2"><b>Role-based access:</b> Access to personal data is granted only to authorized staff members who require access to perform their job responsibilities. Access rights are assigned based on job roles and responsibilities, and access is regularly reviewed and updated as needed.</li>
          <li className="mb-2"><b>Confidentiality agreements</b>: All staff members who have access to customers' personal data are required to sign confidentiality agreements that legally bind them to maintain the confidentiality and privacy of the data they handle.</li>
          <li className="mb-2"><b>Training and awareness:</b> We provide comprehensive training to our staff members on data protection, privacy best practices, and their responsibilities regarding the handling of customers' personal data.</li>
          <li className="mb-2"><b>Monitoring and auditing:</b> We employ monitoring and auditing mechanisms to track and log access to personal data. This allows us to detect and investigate any unauthorized or suspicious activities promptly.</li>
        </ul>

        <p className="text-gray-700 py-5">
          By implementing these measures, we strive to ensure that only authorized personnel with a legitimate need for accessing customers' personal data have the necessary permissions. We regularly review and update our access controls to maintain the highest level of data security.
        </p>

        <h2 className="text-2xl font-bold mb-4">10. Access Logging</h2>
        <p className="text-gray-700 py-5">
          We maintain a comprehensive access logging system to track and monitor access to personal data. This logging system records the following information:
        </p>

        <ul className="list-disc ml-8 text-gray-800 mb-2">
          <li className="mb-2">Date and time of access</li>
          <li className="mb-2">User or staff member accessing the data</li>
          <li className="mb-2">Type of data accessed</li>
          <li className="mb-2">Purpose or reason for accessing the data</li>
          <li className="mb-2">IP address or device information of the accessing party</li>
        </ul>
        <p className="text-gray-700 py-5">
          The access logs serve several purposes, including:
        </p>
        <ul className="list-disc ml-8 text-gray-800 mb-2">
          <li className="mb-2">Security Monitoring: We review access logs to detect and prevent unauthorized access or suspicious activities related to personal data.</li>
          <li className="mb-2">Audit and Compliance: Access logs help us ensure compliance with privacy regulations and internal policies by providing an audit trail of data access activities.</li>
          <li className="mb-2">Incident Investigation: In the event of a security incident or data breach, access logs can be used to investigate the incident, identify the scope of the breach, and take appropriate remedial actions.</li>
        </ul>

        <p className="text-gray-700 py-5">
          We maintain access logs for a defined retention period as required by applicable laws and regulations. These logs are securely stored and accessible only to authorized personnel for legitimate purposes.
        </p>

        <p className="text-gray-700 py-5">
          By logging access to personal data, we enhance the security and accountability of our systems, ensuring that any unauthorized or inappropriate access can be promptly identified and addressed.
        </p>
        <h2 className="text-2xl font-bold mb-4">11. Security Incident Response</h2>
        <p className="text-gray-700 py-5">
          We have implemented a comprehensive security incident response policy to promptly and effectively respond to any security incidents that may occur. This policy includes the following key elements:
        </p>

        <ul className="list-disc ml-8 text-gray-800 mb-2">
          <li className="mb-2"><b>Detection and Reporting: </b>We employ monitoring systems and procedures to detect potential security incidents. Any suspected or confirmed security incidents are promptly reported to our designated incident response team.</li>
          <li className="mb-2"><b>Response and Mitigation:</b> Upon detection of a security incident, our incident response team initiates immediate actions to contain and mitigate the impact of the incident. This may involve isolating affected systems, implementing temporary measures, and taking steps to prevent further unauthorized access or data loss.</li>
          <li className="mb-2"><b>Investigation and Analysis:</b> We conduct a thorough investigation of security incidents to determine the cause, scope, and potential impact. This includes analyzing system logs, conducting forensic examinations, and gathering relevant evidence.</li>
          <li className="mb-2"><b>Communication and Notification: </b>In the event of a security incident that poses a risk to individuals' rights and freedoms, we will notify affected individuals and any relevant authorities in accordance with applicable laws and regulations. We will provide clear and timely communication about the incident, its impact, and any recommended actions individuals should take to protect themselves.</li>
          <li className="mb-2"><b>Remediation and Preventive Measures:</b> After resolving a security incident, we take appropriate measures to remediate the situation and prevent similar incidents from occurring in the future. This may involve implementing additional security controls, updating policies and procedures, and conducting staff training and awareness programs.</li>
        </ul>

        <p className="text-gray-700 py-5">
          Our security incident response policy is regularly reviewed, tested, and updated to ensure its effectiveness in addressing new and emerging threats. We are committed to maintaining the confidentiality, integrity, and availability of personal data and continuously improving our security measures
        </p>

        <h2 className="text-2xl font-bold mb-4">12. User Consent and Choices</h2>

        <p className="text-gray-700 py-5">
          By using our app, you consent to the collection, processing, and storage of data as described in this policy. You have the right to access, rectify, or delete this  data. If you wish to exercise these rights or have any concerns about your data privacy, please contact us using the information provided at the end of this policy.
        </p>


        <h2 className="text-2xl font-bold mb-4">13. Children's Privacy</h2>
        <p className="text-gray-700 py-5">
          The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will take steps to delete such information.
        </p>

        <h2 className="text-2xl font-bold mb-4">14. Compliance with GDPR, Kenyan Laws, and Other Data Protection Laws:</h2>
        <p className="text-gray-700 py-5">
          We are committed to protecting your privacy and ensuring the security of your personal information in accordance with applicable laws and regulations, including the General Data Protection Regulation (GDPR) in the European Union and the data protection laws of Kenya. This Privacy Policy outlines how we collect, use, and safeguard your data, taking into consideration the principles and requirements set forth in these regulations. By using our app, you acknowledge that your personal information will be handled in compliance with the GDPR, Kenyan laws, and other relevant data protection laws.
          In addition to the GDPR and Kenyan laws, we also seek to be compliant with other applicable data protection laws that may apply to our operations. We recognize the importance of privacy and data protection and strive to uphold high standards of compliance and accountability.
        </p>

        <h2 className="text-2xl font-bold mb-4">15. Changes to this Privacy Policy</h2>
        <p className="text-gray-700 py-5">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the revised policy on the App. We encourage you to review this policy periodically for any updates.        </p>
      </div>
    </div>

  </Layout>
);

export default IndexPage;
